<template>
  <v-card elevation="0">
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="12" sm="4" md="4">
          <v-text-field outlined v-model="searchPatients.name" append-icon="mdi-magnify" label="Buscar"/>
        </v-col>

        <!-- select para filtrar por status -->
        <v-col cols="12" sm="4" md="4">
          <v-select v-model="searchPatients.status" :items="status" placeholder="Filtrar Status" menu-props="auto" outlined
            single-line/>
        </v-col>

        <v-col cols="12" sm="2" md="2"/>

        <v-col cols="12" sm="3" md="3">
          <!-- <excel
            :data="data || []"
            :json_fields="json_fields_patient"
            button_name="Exportar"
            icon="mdi-download"
            file_name="pacientes.xls"
          /> -->
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table :loading="!filteredPatients" no-data-text="Nenhum dado encontrado" :headers="headers" :items="filteredPatients"
      item-key="id" sort-by="id">
      <!-- Patient -->
      <template v-slot:[`item.name`]="{ item }">
        <div class="d-flex flex-row align-center" @click="onPressProfile(item)">
          <v-avatar class="pa-10" size="45">
            <img v-if="item.profile_icon_url" alt="Avatar" :src="item.profile_icon_url" />
            <v-icon v-else size="55"> mdi-account-circle </v-icon>
          </v-avatar>
          <div class="flex-column pl-5">
            <div class="body-1">{{ item.name }}</div>
            <div class="caption font-weight-light">{{ item.age }} anos</div>
            <div class="caption font-weight-light">Status: {{ item.role_id }}</div>
          </div>
        </div>
      </template>
      <!-- update Last -->
      <template v-slot:[`item.updateLast`]="{ item }">
        <div @click="onPressProfile(item)">
          {{ item.updateLast }}
        </div>
      </template>
      <!-- PA -->
      <template v-slot:[`item.PA`]="{ item }">
        <div @click="onPressProfile(item)">
          {{ item.PA }}
        </div>
      </template>
      <!-- FC -->
      <template v-slot:[`item.FC`]="{ item }">
        <div @click="onPressProfile(item)">
          {{ item.FC }}
        </div>
      </template>
      <!-- FR -->
      <!-- <template v-slot:[`item.FR`]="{ item }">
        <div>
          {{ item.FR }}
        </div>
      </template> -->
      <!-- Temperature -->
      <template v-slot:[`item.temperature`]="{ item }">
        <div @click="onPressProfile(item)">{{ item.temperature }} °C</div>
      </template>
      <!--birth date -->
      <template v-slot:[`item.temperature`]="{ item }">
        <div @click="onPressProfile(item)">{{ item.temperature }} °C</div>
      </template>
      <!-- Saturation -->
      <template v-slot:[`item.saturation`]="{ item }">
        <div @click="onPressProfile(item)">{{ item.saturation }} %</div>
      </template>
      <!-- Sleep -->
      <template v-slot:[`item.sleep`]="{ item }">
        <div @click="onPressProfile(item)">{{ item.sleep }} Hrs</div>
      </template>
      <!-- Status -->
      <template v-slot:[`item.status`]="{ item }">
        <div @click="onPressProfile(item)" class="d-flex flex-row align-center">
          <v-icon :color="item.statusColor" large>mdi-circle-small</v-icon>
          {{ item.status }}
        </div>
      </template>
      
      <template v-slot:[`item.in_attendance`]="{ item }">
        <div @click="onPressProfile(item)" class="d-flex flex-row align-center">
          {{ item.in_attendance == true ? 'Em atendimento' : '' }}
          <v-spacer></v-spacer>
          <v-icon v-if="item.in_attendance" :color="item.in_attendance == true ? 'orange' : 'grey'"
            large>mdi-circle-small</v-icon>
        </div>
      </template>
      <!-- menu -->
      <template v-slot:[`item.menu`]="{ item }">
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn v-bind="attrs" v-on="on" text>
                <v-icon dark>{{ item.menu }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{
                path: '/patient/'
                  + `${item.id}`,
                params: {
                  id: item.id,
                },
              }" link>
                <v-list-item-title style="color: #64676c">
                  Ver perfil
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.role_id === 'Ativo'" link @click="emitDisableEvent(item.id)">
                <v-list-item-title style="color: red">
                  Desativar paciente
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-else link @click="emitAvailableEvent(item.id)">
                <v-list-item-title style="color: green">
                  Ativar paciente
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// import Excel from "../../components/Excel/Excel.vue";

export default {
  props: ["data", "openFilter"],
  components: {
    // Excel,
  },
  data() {
    return {
      json_fields_patient: {
        Nome: {
          field: "name",
          callback: (e) => {
            let textFormatted = "";

            for (var i = 0; i < e.split(" ").length; i++) {
              textFormatted += `${e.split(" ")[i]}\n`;
            }

            return textFormatted;
          },
        },
        Idade: "age",
        "": "role_id",
        PA: "PA",
        FC: "FC",
        Temperatura: "temperature",
        Saturação: "saturation",
        "Sono total": "sleep",
        Dispositivo: "status",
      },
      searchPatients: {
       name: null ,
       status: null ,
      },
      status: [
        {
          text: "Selecione um o Status do paciente",
          value: null,
        },
        {
          text: "Ativo",
          value: "Ativo",
        },
        {
          text: "Inativo",
          value: "Inativo",
        },
      ],
    };
  },
  methods: {
    emitDisableEvent(id) {
      this.$emit("delete", id);
    },
    emitAvailableEvent(id) {
      this.$emit("avaible", id);
    },
    onPressProfile(patient) {
      this.$emit("onPressProfile", patient.id);
    },

  },
  computed: {
    filteredPatients() {
      if (!this.searchPatients?.name && !this.searchPatients?.status) return this.data;
      
      return this.data.filter(patient => {
        const validName = patient.name.toLowerCase().includes(this.searchPatients.name.toLowerCase())
        const validStatus = !this.searchPatients.status || patient.role_id === this.searchPatients.status

        return validName && validStatus
      })
    },
    headers() {
      return [
        { text: "Paciente", value: "name", width: 240 },
        { text: "PA", value: "PA", width: 75 },
        { text: "FC", value: "FC", width: 75 },
        { text: "TEMP", value: "temperature", width: 100 },
        { text: "SAT.", value: "saturation", width: 100 },
        { text: "SONO", value: "sleep", width: 100 },
        { text: "PULSEIRA", value: "status", width: 100 },
        { text: "STATUS", value: "role_id", width: 100 },
        { text: "Acesso", value: "menu", width: 30 },
      ];
    },
  },
};
</script>

<style>
.iconS {
  margin-bottom: 20%;
}
</style>
