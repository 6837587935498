<template>
  <v-row class="pa-2">
    <Overlay :loading="loading" />
    <delete-user-dialog
      @accepted="onDeletedPatientDialog"
      :open="openDeletePatient"
      @close="openDeletePatient = false"
      :key="allPatients && allPatients.length"
    />
    <!-- <filter-dialog
      @onActive="showFilteredPatients"
      :open.sync="openFilter"
      :width="400"
      title="Filtrar pacientes"
      type="patients"
    /> -->
    <v-col cols="12" class="text-h5 black--text pb-0">
      <v-row>
        <v-col cols="6" sm="8" md="10">Pacientes</v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="subtitle-2 grey--text pt-0">
      Lista de Pacientes cadastrados
    </v-col>
    <v-col cols="12">
      <Table
        :data="allPatients || []"
        @delete="deletePatient"
        @avaible="turnActivePatient"
        :openFilter.sync="openFilter"
        @onPressProfile="onChangePage('/patient/' + $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Table from "../../../components/Table/Table.vue";
// import FilterDialog from "../../../components/Dialogs/Filter.vue";
import DeleteUserDialog from "../../../components/Dialogs/DeleteUser.vue";
import Overlay from "../../../components/Overlay/Overlay.vue";

export default {
  components: {
    Table,
    DeleteUserDialog,
    Overlay,
    // FilterDialog,
  },
  data: () => ({
    loading: false,
    openFilter: false,
    allPatients: [],
    turn_user_active: {
      role_id: 12
    },
    turn_user_disabled: {
      role_id: 13
    },
    openBeforeCreatePatient: false,
    openCreatePatient: false,
    openCreatedPatient: false,
    openDeletePatient: false,
    deletePatientId: null,
    searchPatientTable: "",
    lastUpdateData: "09 nov às 14:32h",
    lastPatientCreatedId: null,
    highRiskAlertMedia: 0,
    lowRiskAlertMedia: 0,
    currentDate: new Date().toLocaleDateString("pt-br", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
    activePatients: 0,
    inactivePatients: 0,
    dashboardPatients: [
      {
        id: 1,
        title: "Pacientes sendo acompanhados",
        value: "00",
        color: "#1C3B7B",
      },
      {
        id: 1,
        title: "Pacientes com exames",
        value: "00",
        color: "#1C3B7B",
      },
    ],
  }),
  mounted() {
    this.getPatients();
    // this.allPatients = this.patients;
    this.dashboardPatients[0].value =
      this.patients.length < 10
        ? "0" + this.patients.length
        : this.patients.length;
  },
  methods: {
    async getPatients() {
      this.loading = true;
      try {
        let response = await this.$axios.get(`users/`, { 
          params:{ list: true  } 
        }).catch((err) => console.log("Get patients error: ", err));

        response = response.data;
        response = response.map((patient) => {
          const PatientLastSave = patient.receive_last_items;

          if (PatientLastSave.alert == null) {
            PatientLastSave.alert = {
              id: "null",
            };
          }

          // Server-level processing possible
          if (PatientLastSave.device_alert == null) {
            PatientLastSave.device_alert = {
              status: "offline",
              created_at: null,
            };
          }

          // Server-level processing possible
          if (PatientLastSave.ecg == null) {
            PatientLastSave.ecg = {
              sbp: 0,
              dbp: 0,
              bpm: 0,
              blood_oxygen: 0,
            };
          }

          // Server-level processing possible
          if (PatientLastSave.temperature == null) {
            PatientLastSave.temperature = { real_temperature: 0 };
          }

          // Server-level processing possible
          if (PatientLastSave.sleep == null) {
            PatientLastSave.sleep = {
              deep_sleep_total: 0,
              light_sleep_total: 0,
            };
          }

          // const isPatientOnline = Date.parse(PatientLastSave.device_alert.updated_at) > Date.now() - 20 * 60000;
          const isPatientActive = patient.role_id == 12
          
          return {
            id: patient.id,
            name: patient.name,
            age: patient.age,
            profile_icon_url: patient.profile_icon_url,
            PA: `${PatientLastSave?.ecg?.dbp || 0}x${PatientLastSave?.ecg?.sbp || 0}`,
            FC: PatientLastSave?.ecg?.bpm || 0,
            // FR: 4.0,
            temperature: PatientLastSave.temperature?.real_temperature || 0,
            saturation: PatientLastSave.ecg?.blood_oxygen,
            sleep: (
              (PatientLastSave.sleep.deep_sleep_total +
                PatientLastSave.sleep.light_sleep_total) /
              60
            ).toFixed(1),
            status: PatientLastSave.device_alert.status == "online"
              ? "Ligado"
              : "Desligado",
            role_id: isPatientActive ? "Ativo" : "Inativo",
            statusColor: PatientLastSave.device_alert.status == "online"
              ? "green"
              : "red",
            in_attendance: PatientLastSave.in_attendance,
            menu: "mdi-dots-vertical",
          };
        });

        this.allPatients = response
      
        if (this.$route.params.role_id){
          console.log(this.$route.params.role_id)
          this.allPatients = this.allPatients.filter(patient => patient.role_id === this.$route.params.role_id)
        }
        if(this.$route.params.status){
          console.log('status:' + this.$route.params.status)
          this.allPatients = this.allPatients.filter(patient => patient.status == this.$route.params.status && patient.role_id == 'Ativo')
        }

        this.loading = false;
      } catch (error) {
        this.loading = false; 
        const msg = error.response.error;

        console.log("patient error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar o paciente." + msg,
        });
      }
        
    },
    async onDeletedPatientDialog() {
      this.openDeletePatient = false;
      try {
        const patient = this.findPatient(this.deletePatientId);

        await this.$axios.put("users/"+ this.deletePatientId, this.turn_user_disabled);

        await this.$store.dispatch("patients/deletePatient", {
          patient: patient,
        });

        this.getPatients();
        
        await this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Paciente desativado com sucesso",
        });
      } catch (error) {
        this.getPatients();

        console.log("Delete patient error: ", error);
        
        this.$swal({
          toast: false,
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar desativar o paciente",
        });
      }
    },
    async turnActivePatient(id){
      this.deletePatientId = id;

      try {
        await this.$axios.put("users/"+ this.deletePatientId, this.turn_user_active);
      
      this.getPatients();
      
       await this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Paciente reativado com sucesso",
        });
      } catch (error) {
        console.log("reactivate patient error: ", error);
        this.$swal({
          toast: false,
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar reativar o paciente",
        });
      }
    },
    async showFilteredPatients(event) {
      try {
        this.loading = true;

        const params = Object.assign(
          {
            doctor_id: this.currentUser.id,
            report_type: event.value.type,
            risk: event.value.risk,
          },
          { [event.value.name]: event.value.value }
        );

        const response = await this.$axios("reports", {
          params: params,
        });

        this.allPatients = this.findPatients(response.data);
        this.openFilter = false;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Filter patients error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar filtrar os pacientes.",
        });
      }
    },
    afterClickedPatientDialog() {
      this.openCreatePatient = true;
      this.openBeforeCreatePatient = false;
    },
    afterCreatePatientDialog(user) {
      (this.lastPatientCreatedId = user.id), (this.openCreatePatient = false);
      this.openBeforeCreatePatient = false;
      this.openCreatedPatient = true;
    },
    deletePatient(id) {
      this.deletePatientId = id;
      this.openDeletePatient = true;
    },
    onChangePage(path) {
      this.$router.push({
        path: path,
      });
    },
    onCloseCreatePatientDialog() {
      this.openCreatePatient = false;
      this.openBeforeCreatePatient = false;
    },
    onCloseCreatedPatientDialog(id) {
      this.openCreatedPatient = false;
      if (id == null) return;
      this.$router.push({
        path: "/patient/",
        // + id,
        // params: {
        //   id: id,
        // },
      });
    },
    updateEveryXminutes(minutes) {
      setInterval(() => {
        this.showAllPatients();
      }, minutes * 60000);
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
      alerts: (state) => state.alerts.alerts,
      patients: (state) => state.patients.patients,
    }),
    ...mapGetters({
      connectedPatients: "patients/connectedPatients",
      disconnectedPatients: "patients/disconnectedPatients",
      findPatient: "patients/findPatient",
      findPatients: "patients/findPatients",
    }),
  },
  // watch: {
  //   "$store.state.patients.patients"(val) {
  //     this.allPatients = val;
  //   },
  // },
};
</script>

<style></style>
