var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.searchPatients.name),callback:function ($$v) {_vm.$set(_vm.searchPatients, "name", $$v)},expression:"searchPatients.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{attrs:{"items":_vm.status,"placeholder":"Filtrar Status","menu-props":"auto","outlined":"","single-line":""},model:{value:(_vm.searchPatients.status),callback:function ($$v) {_vm.$set(_vm.searchPatients, "status", $$v)},expression:"searchPatients.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}}),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}})],1)],1),_c('v-data-table',{attrs:{"loading":!_vm.filteredPatients,"no-data-text":"Nenhum dado encontrado","headers":_vm.headers,"items":_vm.filteredPatients,"item-key":"id","sort-by":"id"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center",on:{"click":function($event){return _vm.onPressProfile(item)}}},[_c('v-avatar',{staticClass:"pa-10",attrs:{"size":"45"}},[(item.profile_icon_url)?_c('img',{attrs:{"alt":"Avatar","src":item.profile_icon_url}}):_c('v-icon',{attrs:{"size":"55"}},[_vm._v(" mdi-account-circle ")])],1),_c('div',{staticClass:"flex-column pl-5"},[_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"caption font-weight-light"},[_vm._v(_vm._s(item.age)+" anos")]),_c('div',{staticClass:"caption font-weight-light"},[_vm._v("Status: "+_vm._s(item.role_id))])])],1)]}},{key:"item.updateLast",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.onPressProfile(item)}}},[_vm._v(" "+_vm._s(item.updateLast)+" ")])]}},{key:"item.PA",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.onPressProfile(item)}}},[_vm._v(" "+_vm._s(item.PA)+" ")])]}},{key:"item.FC",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.onPressProfile(item)}}},[_vm._v(" "+_vm._s(item.FC)+" ")])]}},{key:"item.temperature",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.onPressProfile(item)}}},[_vm._v(_vm._s(item.temperature)+" °C")])]}},{key:"item.saturation",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.onPressProfile(item)}}},[_vm._v(_vm._s(item.saturation)+" %")])]}},{key:"item.sleep",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.onPressProfile(item)}}},[_vm._v(_vm._s(item.sleep)+" Hrs")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center",on:{"click":function($event){return _vm.onPressProfile(item)}}},[_c('v-icon',{attrs:{"color":item.statusColor,"large":""}},[_vm._v("mdi-circle-small")]),_vm._v(" "+_vm._s(item.status)+" ")],1)]}},{key:"item.in_attendance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center",on:{"click":function($event){return _vm.onPressProfile(item)}}},[_vm._v(" "+_vm._s(item.in_attendance == true ? 'Em atendimento' : '')+" "),_c('v-spacer'),(item.in_attendance)?_c('v-icon',{attrs:{"color":item.in_attendance == true ? 'orange' : 'grey',"large":""}},[_vm._v("mdi-circle-small")]):_vm._e()],1)]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(item.menu))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{
              path: '/patient/'
                + "" + (item.id),
              params: {
                id: item.id,
              },
            },"link":""}},[_c('v-list-item-title',{staticStyle:{"color":"#64676c"}},[_vm._v(" Ver perfil ")])],1),(item.role_id === 'Ativo')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.emitDisableEvent(item.id)}}},[_c('v-list-item-title',{staticStyle:{"color":"red"}},[_vm._v(" Desativar paciente ")])],1):_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.emitAvailableEvent(item.id)}}},[_c('v-list-item-title',{staticStyle:{"color":"green"}},[_vm._v(" Ativar paciente ")])],1)],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }